<template>
  <lf-modal
    :title="modalTitle"
    :back-button-visible="showBackButton"
    :close="close"
    @step-back="goBack"
  >
    <template v-if="!isSelfFunded" #type>
      <div class="rounded bg-gray-100 px-2 py-1-5">
        {{ productTypeName }}
      </div>
    </template>

    <template #header>
      <lf-tab :active="currentTab == TABS[0]" @click="selectTab(0)">
        {{ $t("DEALS.DEAL_PROGRESS.OFFER_DETAILS") }}
      </lf-tab>
      <lf-tab :active="currentTab == TABS[1]" @click="selectTab(1)">
        {{ $t("DEALS.DEAL_PROGRESS.REQUIRED_STIPS") }}
      </lf-tab>
    </template>

    <template #content>
      <form novalidate @submit="onSubmit">
        <div
          class="flex-col p-6"
          :class="{
            'overflow-y-scroll': currentTab == TABS[0],
            'overflow-y-auto': !(currentTab == TABS[0])
          }"
        >
          <offer-details-choose-product-type
            v-if="isSelfFunded && currentTab === TABS[0]"
            v-model="productNameToUse"
            :product-types="availableProductTypes"
            class="mb-5"
          />
          <div v-show="currentTab == TABS[0]">
            <div class="flex flex-col">
              <div class="px-10 md:px-0 grid grid-flow-row gap-x-3 mt-6">
                <lf-textarea
                  :value="offerNote"
                  :placeholder="$t('OFFERS.NOTES.DISPLAYED_IN_BP')"
                  name="offer_note"
                  noresize
                  show-label
                  rows="3"
                  @key-released="offerNote = $event"
                />
              </div>
            </div>
            <div
              v-for="(group, groupKey) in fields"
              :key="groupKey"
              class="flex flex-col"
            >
              <lf-h4>
                {{ wordsFirstLetterToUpper(`${groupKey}`, "_") }}
              </lf-h4>
              <div
                class="px-10 md:px-0 grid grid-cols-2 grid-flow-row gap-x-3 mt-6"
              >
                <template v-for="(rules, name) in group" :key="name">
                  <product-read-only-field
                    v-if="String(name) === 'total_initial_payment'"
                    :product-type-id="parseInt(`${productTypeId}`)"
                    :section="section"
                    :value="name && offer[name] ? `${offer[name]}` : ''"
                    :name="`${name}`"
                    :rules="rules ? `${rules}` : ''"
                    :initial-payment="initialPayment"
                    :currency="values['currency']"
                    class="sm:h-23 sm:pt-12 xm:h-18 xm:pt-6 xl:h-11-5 xl:pt-0 mb-5"
                  />
                  <product-type-field
                    v-else
                    :value="name && offer[name] ? `${offer[name]}` : ''"
                    :product-type-id="parseInt(`${productTypeId}`)"
                    :rules="rules ? `${rules}` : ''"
                    :name="`${name}`"
                    :section="section"
                    :start-date="offer.start_date || undefined"
                    :calculated-term="termLength || undefined"
                    :currency="values['currency']"
                    :disabled="isDisabled(name)"
                  />
                </template>
              </div>
            </div>
          </div>

          <template v-if="productTypeId">
            <stips
              v-show="currentTab === TABS[1]"
              :preselect-from-workflow-template="!editMode"
              :required-stips="
                offer?.stips.length ? offer.stips : activeDeal.stips
              "
              :product-types="[productTypeId]"
              hide-title
            />
          </template>
        </div>
        <div class="p-6 flex justify-end border-t space-x-4">
          <outline-button @click="closeModal()">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button
            type="submit"
            :disabled="isSubmitting"
            data-cy="next-button"
          >
            {{
              editMode || currentTab == TABS[1]
                ? $t("COMMON.SAVE")
                : $t("DEALS.DEAL_PROGRESS.SET_REQUIRED_STIPS")
            }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import type { SubmissionContext } from "vee-validate";
import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { dispatchAction } from "@/helpers/vee-validate";
import {
  wordsFirstLetterToUpper,
  getValueForDateField,
  removeCurrencyPrefix
} from "@/helpers/formatting";

import {
  OFFER_SECTION,
  FUNDING_SECTION,
  PAYMENT_PERIOD,
  PRODUCT_TYPE
} from "@/helpers/constants";
import Stips from "@/views/deals/components/Stips.vue";
import ProductTypeField from "../components/ProductTypeField.vue";
import ProductReadOnlyField from "../components/ProductReadOnlyField.vue";
import OfferDetailsChooseProductType from "../components/OfferDetailsChooseProductType.vue";
import { PRODUCT_TYPE_REVERSE_MAPPING } from "@/helpers/constants/workflow";
import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import type { FinProductNum } from "@/models/common";
import type { FinProduct } from "@/models/workflows";
import type {
  ILenderPlacement,
  IOffer,
  IOfferFundingData
} from "@/models/funders";
import type { IApplication, IStip } from "@/models/applications";
import LfModal from "@/components/ui/Modal.vue";
import { useDealsBase } from "@/hooks/deals";
import type { ProductType } from "@/models/options";
import {
  FUNDING_COMMISSION_FIELD,
  TERM_LENGTH_FIELD,
  TOTAL_INITIAL_PAYMENT_FIELD
} from "@/helpers/constants/offers";
import { WORKFLOW_STAGES } from "@/helpers/constants";
import {
  useActiveStage,
  useActiveWorkflowTemplateStages
} from "@/hooks/workflow";
import { looksLikeNumber } from "@/helpers/common";
import { OfferSaveMode } from "@/enums/offers";
import isEmpty from "lodash/isEmpty";

type OfferDetailsActions = SubmissionContext<{
  expires_at: Date | null;
  id: string;
  application_id: string;
  funding_advisor_full_name: string;
  placement: ILenderPlacement;
  sent_by: string;
  stips: IStip[];
  status: number;
  status_date: string;
  funded_date: string;
  offer_generated_date: string | null;
  offer_expiration_date: string | null;
  funding_data: IOfferFundingData;
  created_at: string;
  start_date?: string | null | undefined;
  end_date?: string | null | undefined;
  application: IApplication;
  term?: number | undefined;
  max_term?: number | undefined;
  term_length?: number | undefined;
  payment_frequency?: 1 | 3 | 4 | 2 | undefined;
  number_of_payments?: number | undefined;
  product_type?: number | undefined;
  currency?: string | undefined;
  interest_charged?: string | undefined;
  initial_draw_fee?: number | undefined;
  additional_fee?: number | null;
  subsequent_draw_fee?: number | null;
}>;

const props = defineProps({
  placementId: {
    type: [String, Number],
    default: ""
  },
  offer: {
    type: Object as PropType<
      IOffer & { product_type?: number | string | null }
    >,
    default: () => {
      return {
        funding: null,
        funding_amount: null,
        payback_amount: null,
        number_of_payments: null,
        payment_frequency: null,
        term_length: null,
        offer_amount: null,
        sell_factor_rate: null,
        payment_amount: null,
        buy_factor_rate: null,
        max_line_of_credit: null,
        max_term: null,
        monthly_interest: null,
        first_draw_commission: null,
        subsequent_draw_commission: null,
        max_facility_amount: null,
        expense_deposit: null,
        origination_fee: null,
        renewal: null,
        stips: [],
        expires_at: null,
        offer_generated_date: null,
        currency: null,
        interest_charged: null,
        initial_draw_fee: null,
        additional_fee: null,
        subsequent_draw_fee: null,
        base_rate: 1,
        note: null
      };
    }
  },
  productType: {
    type: String,
    required: false
  },
  editMode: {
    type: Boolean,
    default: false
  },
  // used for funded offers
  fundedInfo: {
    type: Boolean,
    default: false
  },
  // used only for self funded offers
  isSelfFunded: {
    type: Boolean,
    default: false
  },
  // defined just to prevent prop inheritance warning
  value: {
    required: false
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const { getters, dispatch } = useStore();
const { t } = useI18n();
const { activeDeal } = useDealsBase();
const { activeTemplateStages } = useActiveWorkflowTemplateStages();
const { activeStage, isOfferStage } = useActiveStage();

const availableProductTypes = computed(() =>
  compact(
    activeStage.value?.products?.reduce<Array<string>>((products, product) => {
      if (product.enabled) {
        products.push(capitalize(product.type.replaceAll("_", " ")));
      }
      return products;
    }, [])
  )
);

const TABS = ["details", "stips"];
const currentTab = ref(TABS[0]);

const offerNote = ref(props.offer.note?.note);

const section = props.fundedInfo ? FUNDING_SECTION : OFFER_SECTION;

const productTypes = computed<Record<FinProductNum, string>>(
  () => getters["options/productTypes"]
);

const hasPlacmentStage = computed(
  () =>
    !!activeTemplateStages.value?.find(
      (stage) => stage.type === WORKFLOW_STAGES.PLACEMENT
    )
);

// used only for self funded offers
const productNameToUse = ref(availableProductTypes.value[0] || "");

const calculateInitialTermLength = () => {
  if (props.offer?.product_type !== PRODUCT_TYPE.RECEIVABLES_PURCHASE) {
    return looksLikeNumber(props.offer?.term_length)
      ? Number(props.offer?.term_length)
      : null;
  }
  if (props.offer?.number_of_payments && props.offer?.payment_frequency) {
    return (
      props.offer.number_of_payments /
      PAYMENT_PERIOD[props.offer.payment_frequency]
    );
  }

  return null;
};

const termLength = ref<string | number | null>(calculateInitialTermLength());

const productTypeName = computed(() => {
  if (
    !!activeStage.value &&
    isOfferStage.value &&
    (!hasPlacmentStage.value || props.isSelfFunded) &&
    !props.productType &&
    !props.offer.product_type
  ) {
    return productNameToUse.value;
  }
  return props.productType || props.offer.product_type || "";
});
const productTypeId = computed(() => {
  if (props.offer.placement?.product_id) {
    return props.offer.placement?.product_id;
  }
  const productTypeIds = Object.keys(productTypes.value) as FinProductNum[];
  if (!hasPlacmentStage.value || props.isSelfFunded) {
    return PRODUCT_TYPE_REVERSE_MAPPING[
      productNameToUse.value.replaceAll(" ", "_").toLowerCase() as FinProduct
    ];
  }
  const pt = productTypeIds.find(
    (typeId) => productTypes.value[typeId] === props.productType
  );
  return pt || props.offer.placement?.product_id;
});

const initialValues = {
  ...props.offer,
  expires_at: props.offer.expires_at
    ? (getValueForDateField(props.offer.expires_at, true) as unknown as Date)
    : null,
  offer_generated_date: getValueForDateField(
    props.offer?.offer_generated_date || "",
    true
  ),
  offer_expiration_date: getValueForDateField(
    props.offer?.offer_expiration_date || "",
    false
  )
};

const isDisabled = (name: string) => {
  if (
    name === TERM_LENGTH_FIELD &&
    (props.productType === PRODUCT_TYPE.RECEIVABLES_PURCHASE ||
      props.offer?.product_type === PRODUCT_TYPE.RECEIVABLES_PURCHASE)
  ) {
    return false;
  }
  return [
    TERM_LENGTH_FIELD,
    FUNDING_COMMISSION_FIELD,
    TOTAL_INITIAL_PAYMENT_FIELD
  ].includes(name);
};

const { handleSubmit, resetForm, values, isSubmitting } = useForm({
  initialValues: { ...initialValues }
});

const initialPayment = computed(() => {
  const { advance_payments, fee } = values;
  const parsedAvancePayments = removeCurrencyPrefix(advance_payments);
  const parsedFee = removeCurrencyPrefix(fee);

  return parsedAvancePayments + parsedFee;
});

const showBackButton = computed(() => currentTab.value === TABS[1]);
const goBack = () => {
  currentTab.value = TABS[0];
};

const modalTitle = computed(() => {
  if (props.editMode) {
    if (props.fundedInfo) {
      return t("DEALS.DEAL_PROGRESS.EDIT_FUNDING_DETAILS");
    } else {
      return t("DEALS.DEAL_PROGRESS.EDIT_OFFER");
    }
  } else if (currentTab.value === TABS[0]) {
    return t("DEALS.DEAL_PROGRESS.SET_OFFER_DETAILS");
  } else {
    return t("DEALS.DEAL_PROGRESS.SET_REQUIRED_STIPS");
  }
});

const fields = computed(() => {
  const getFields: (
    id: string | ProductType | undefined
  ) => Record<string, IOffer> =
    getters[
      props.fundedInfo
        ? "options/productFundingFields"
        : "options/productFields"
    ];
  return getFields(productTypeId.value);
});

const onSubmit = handleSubmit(async (values, actions) => {
  const updatedOffer = {
    ...values,
    id: props.offer.id,
    placement_id: props.placementId,
    application_id: getters["applications/active"].id,
    total_initial_payment: initialPayment.value
  };
  props.editMode || currentTab.value == TABS[1]
    ? await updateOffer(
        updatedOffer as unknown as IOffer,
        actions as OfferDetailsActions
      )
    : selectTab(1);
});

function selectTab(index: number) {
  currentTab.value = TABS[index];
}

const closeModal = () => {
  props.close();
  termLength.value = null;
  currentTab.value = TABS[0];
};

const updateOffer = async (
  values: IOffer & { product_id?: number },
  actions: OfferDetailsActions
) => {
  const payload = {
    offer: {
      ...values,
      stips: values.stips.map((stip) => {
        return {
          id: (stip as IStip & { stip_id?: number }).stip_id || stip.id,
          description: stip.description,
          count: stip.count
        };
      })
    },
    mode: props.editMode ? OfferSaveMode.UPDATE : OfferSaveMode.SAVE
  };

  if (props.isSelfFunded) {
    payload.offer.product_id = productTypeId.value
      ? parseInt(productTypeId.value as string)
      : undefined;
  }

  try {
    if (props.isSelfFunded) {
      const offer = await dispatchAction(
        payload,
        actions,
        "applications/saveSelfFundedOffer"
      );
      if (!isEmpty(offerNote.value)) {
        await dispatch("applications/attachOfferNote", {
          offerId: offer.id,
          note: offerNote.value
        });
      }
    } else if (props.fundedInfo) {
      await dispatchAction(
        {
          id: payload.offer.id,
          offer: payload.offer
        },
        actions,
        "applications/confirmOffer"
      );
    } else {
      const offer = await dispatchAction(
        payload,
        actions,
        "applications/saveOffer"
      );
      if (offer.id && props.offer.note?.note !== offerNote.value) {
        await dispatch("applications/attachOfferNote", {
          offerId: offer.id,
          note: offerNote.value
        });
      }
    }
  } catch {
    return;
  }

  closeModal();
  resetForm();
};

watch(
  () => values.start_date,
  () => {
    const { start_date, end_date } = values;
    if (
      start_date &&
      end_date &&
      Date.parse(start_date) > Date.parse(end_date)
    ) {
      values.end_date = start_date;
    }
  }
);
</script>
